import { motion } from 'framer-motion';
import { routeVariants } from '@/constants/animations';
import styled, { css, keyframes } from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { mobileSize } from '@/styles/theme';

const ANIMATE_TEXT = '{Everyday Life}';

function Home() {
  const [animateText, setAnimateText] = useState<string>('{');
  const isFullText = useMemo(() => animateText.length === ANIMATE_TEXT.length, [animateText]);

  useEffect(() => {
    const max = ANIMATE_TEXT.length;
    const extraCount = 26;
    let i = 1;

    const interval = setInterval(() => {
      setAnimateText(ANIMATE_TEXT.substring(0, i));
      i += 1;
      if (i > max + extraCount) {
        i = 1;
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <HomeWrapper initial="bottom" animate="enter" variants={routeVariants}>
      <div className="pc-label">
        <Label>Lightscale Brings</Label>
        <Label>Blockchain Technology</Label>
        <Label className="animate-container" isBlinking={isFullText}>
          into
          <div className="home-animate-text">
            <span>{animateText.slice(0, 1)}</span>
            {animateText.slice(1, ANIMATE_TEXT.length - 1)}
            {isFullText && <span>{animateText.slice(-1)}</span>}
          </div>
        </Label>
      </div>
      <div className="mobile-label">
        <Label>Lightscale</Label>
        <Label>Brings Blockchain</Label>
        <Label className="animate-container">Technology into</Label>
        <Label isBlinking={isFullText}>
          <div className="home-animate-text">
            <span>{animateText.slice(0, 1)}</span>
            {animateText.slice(1, ANIMATE_TEXT.length - 1)}
            {isFullText && <span>{animateText.slice(-1)}</span>}
          </div>
        </Label>
      </div>

      <Content>
        Lightscale is building Kroma, a universal Ethereum Layer 2 platform that utilizes ZK Proof technology. Kroma
        will start with Optimistic Rollups with ZK Fault Proofs and will subsequently evolve to be an EVM-equivalent ZK
        Rollup. We strive to compose Pragmatic, Adaptive, and User-Friendly L2 solutions for the Ethereum ecosystem. As
        the name suggests, Kroma provides the full spectrum of scaled Ethereum to our customers, creators, and
        developers.
      </Content>
    </HomeWrapper>
  );
}

const Content = styled.span`
  margin-top: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.gray['400']};
  letter-spacing: -0.003em;
  max-width: 640px;

  @media all and (max-width: ${mobileSize}) {
    font-size: 13px;
    line-height: 22px;
  }
`;

const caret = keyframes`
  50% {
    border-color: transparent;
  }
`;

const Label = styled.div<{ isBlinking?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 56px;
  letter-spacing: -0.003em;
  line-height: 74px;

  .home-animate-text {
    margin-left: 13px;
    font-family: 'ChakraPetch';
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 4px;
    border-right: 4px solid ${(p) => p.theme.colors.primary['500']};
    color: ${(p) => p.theme.colors.primary['500']};
    line-height: 64px;
    font-weight: 600;

    ${({ isBlinking }) =>
      isBlinking &&
      css`
        animation: ${caret} 1s steps(1) infinite;
      `}
    span {
      font-family: 'ChakraPetch';
      font-weight: 300;
    }
  }

  @media all and (max-width: ${mobileSize}) {
    font-size: 32px;
    line-height: 46px;

    .home-animate-text {
      margin-top: 8px;
      margin-left: 0;
      font-size: 32px;
      line-height: 32px;
      border-right: 3px solid ${(p) => p.theme.colors.primary['500']};
    }
  }
`;

const HomeWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;

  .pc-label {
    display: block;
  }

  .mobile-label {
    display: none;
  }

  @media all and (max-width: ${mobileSize}) {
    .pc-label {
      display: none;
    }

    .mobile-label {
      display: block;
    }
  }
`;

export default Home;
