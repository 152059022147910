import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import '@/utils/extends';
import './utils/i18n';
import Home from '@/pages/Home';
import MissionPage from '@/pages/Mission';
import Layout from './components/templates/Layout';
import { GlobalStyles } from './styles/globalStyles';
import { theme } from './styles/theme';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import MouseMoveSection from './components/modules/MouseMoveSection';

smoothscroll.polyfill();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <BrowserRouter>
        <MouseMoveSection />
        <AnimatedRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/mission" element={<MissionPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
