import dayjs from 'dayjs';
import numeral from 'numeral';

Number.prototype.comma = function (unit = 2) {
  if (Number(this) <= 0.000000001) {
    return '0';
  }

  let format = '0,00';
  const count = unit;

  Array(count)
    .fill(0)
    .forEach((v, index) => {
      if (index === 0) {
        format += '.';
      }

      format += '0';
    });

  return numeral(this).format(format);
};

String.prototype.comma = function (unit = 2) {
  if (Number(this) <= 0.000000001) {
    return '0';
  }

  let format = '0,00';

  Array(unit)
    .fill(0)
    .forEach((v, index) => {
      if (index === 0) {
        format += '.';
      }

      format += '0';
    });

  return numeral(this).format(format);
};
