import LogoImg from '@/assets/images/logo.png';
import LogoHoverImg from '@/assets/images/logo_hover.png';
import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Menu } from '@/assets/icons/menu.svg';
import { ReactComponent as ArrowUp } from '@/assets/icons/arrow-up.svg';
import KromaLogo from '@/assets/images/kroma-logo-gray.png';
import KromaHoverLogo from '@/assets/images/kroma-logo.png';
import { mobileSize } from '@/styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { HEADER_WIDTH } from '@/constants/layout';

function Header() {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleInternalLink = (path: string) => {
    if (pathname !== path) {
      navigate(path);
    }
  };

  return (
    <Container>
      <MobileNavBar>
        <div className="bar">
          <LogoContainer
            onClick={() => {
              handleInternalLink('/');
              setIsMenuShow(false);
            }}
          >
            <StyledLogoImg className="logo" src={LogoImg} />
            <StyledLogoImg className="logo_hover" src={LogoHoverImg} />
          </LogoContainer>
          <Menu onClick={() => setIsMenuShow((prev) => !prev)} />
        </div>
        <MobileMenu isShow={isMenuShow}>
          <HeaderItem
            onClick={() => {
              handleInternalLink('/mission');
              setIsMenuShow(false);
            }}
            active={pathname === '/mission'}
          >
            Mission
          </HeaderItem>
          <HeaderItem>
            <a
              href="https://lightscale.notion.site/Lightscale-1f2f933aebd7461096223a0d1703a676"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Careers
              <ArrowUp />
            </a>
          </HeaderItem>
          <HeaderItem>
            <a href="mailto:contact@lightscale.io" target="_blank">
              Contact
              <ArrowUp />
            </a>
          </HeaderItem>
          <HeaderItem>
            <a href="https://kroma.network" target="_blank" rel="noopener noreferrer nofollow">
              <img src={KromaLogo} alt="kroma-logo" className="logo" />
              <img src={KromaHoverLogo} alt="kroma-logo-hover" className="logo_hover" />
            </a>
          </HeaderItem>
        </MobileMenu>
      </MobileNavBar>
      <NavBar>
        <LogoContainer onClick={() => handleInternalLink('/')}>
          <StyledLogoImg className="logo" src={LogoImg} />
          <StyledLogoImg className="logo_hover" src={LogoHoverImg} />
        </LogoContainer>
        <aside>
          <ul>
            <HeaderItem onClick={() => handleInternalLink('/mission')} active={pathname === '/mission'}>
              Mission
            </HeaderItem>
            <HeaderItem onClick={() => {}}>
              {/*TODO: 링크 넣기*/}
              <a
                href="https://lightscale.notion.site/Lightscale-1f2f933aebd7461096223a0d1703a676"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Careers
                <ArrowUp />
              </a>
            </HeaderItem>
            <HeaderItem>
              <a href="mailto:contact@lightscale.io" target="_blank" rel="nofollow">
                Contact
                <ArrowUp />
              </a>
            </HeaderItem>
          </ul>
        </aside>
      </NavBar>
      <GradientBorder />
    </Container>
  );
}

const GradientBorder = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(257.95deg, rgba(0, 255, 224, 0.15) 4.89%, rgba(0, 255, 224, 0) 33.78%),
    radial-gradient(4.93% 100% at 67.5% 0%, rgba(202, 202, 202, 0.15) 0%, rgba(202, 202, 202, 0.15) 100%),
    radial-gradient(36.94% 144.69% at 46.09% 0%, rgba(253, 132, 255, 0.15) 0%, rgba(253, 132, 255, 0) 100%),
    linear-gradient(112.48deg, rgba(0, 163, 255, 0.15) 14.59%, rgba(0, 163, 255, 0) 55.06%), #ffffff;

  @media all and (max-width: ${mobileSize}) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  @media all and (min-width: calc(${mobileSize} + 1px)) {
    &:hover,
    &:active {
      .logo {
        display: none;
      }

      .logo_hover {
        display: block;
      }
    }
  }

  @media all and (max-width: ${mobileSize}) {
    &:active {
      .logo {
        display: none;
      }

      .logo_hover {
        display: block;
      }
    }
  }
`;

const StyledLogoImg = styled.img`
  width: 147.67px;
  height: 20px;
  cursor: pointer;
  display: block;

  &.logo_hover {
    display: none;
  }

  @media all and (max-width: ${mobileSize}) {
    width: 118.14px;
    height: 16px;
  }
`;

const NavBar = styled.div`
  ${(p) => p.theme.common.width}
  height: 80px;
  display: flex;
  padding: 0 20px;
  max-width: ${HEADER_WIDTH + 40}px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media all and (max-width: ${mobileSize}) {
    display: none;
  }

  ul {
    gap: 64px;
  }

  ul + ul {
    margin-left: 130px;
  }

  ul,
  aside {
    display: flex;
  }
`;

const MobileNavBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  ${(p) => p.theme.common.width}
  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    z-index: 1;
    padding: 0 20px;
    background-color: #fff;
    height: 60px;
  }

  svg {
    cursor: pointer;
  }

  li ul {
    display: flex;

    button {
      color: rgba(0, 0, 0, 0.2);
      font-size: 18px;
    }
  }

  @media all and (min-width: ${mobileSize}) {
    display: none;
  }
`;

const MobileMenu = styled.ul<{ isShow: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: -300px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 56px 20px;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  gap: 42px;

  li,
  li > a {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    gap: 3px;
    cursor: pointer;

    svg path {
      fill: ${(p) => p.theme.colors.gray['400']};
    }
  }

  ${(p) =>
    p.isShow &&
    `
    top: 100%;
  `}
`;

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(120px);
  z-index: 1;
  width: 100%;

  & > div {
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //margin: 0 auto;
  }
`;

const HeaderItem = styled.li<{ active?: boolean }>`
  &,
  & > a {
    ${(p) => p.theme.common.flexCenter};
    width: fit-content;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: ${(p) => (p.active ? p.theme.colors.primary['500'] : p.theme.colors.gray['400'])};
    cursor: pointer;
    gap: 2px;

    svg path {
      fill: ${(p) => p.theme.colors.gray['400']};
    }

    img {
      width: 86px;
      height: 20px;

      &.logo {
        display: block;
      }

      &.logo_hover {
        display: none;
      }
    }

    &:hover {
      color: ${(p) => p.theme.colors.gray['950']};

      img {
        &.logo {
          display: none;
        }

        &.logo_hover {
          display: block;
        }
      }

      svg path {
        fill: ${(p) => p.theme.colors.gray['950']};
      }
    }

    &:active {
      color: ${(p) => p.theme.colors.primary['500']};

      svg path {
        fill: ${(p) => p.theme.colors.gray['500']};
      }
    }
  }

  &:nth-of-type(4) {
    margin-top: 16px;
  }

  @media all and (max-width: ${mobileSize}) {
    &,
    & > a {
      color: ${(p) => (p.active ? p.theme.colors.primary['500'] : p.theme.colors.gray['400'])};

      &:active {
        color: ${(p) => p.theme.colors.primary['500']};
      }
    }
  }
`;

export default Header;
