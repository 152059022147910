import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from '@/components/modules/Header';
import Footer from '../modules/Footer';
import { mobileSize } from '@/styles/theme';
import { HEADER_WIDTH } from '@/constants/layout';

function Layout() {
  return (
    <>
      <Wrapper>
        <Header />
        <Main>
          <Container>
            <Outlet />
          </Container>
        </Main>
        <Footer />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Main = styled.main`
  height: 100%;
  min-height: 700px;
  display: flex;
`;

const Container = styled.section`
  max-width: ${HEADER_WIDTH + 40}px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export default Layout;
