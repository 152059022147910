import styled from 'styled-components';
import KromaLogo from '@/assets/images/kroma-logo-white.png';
import KromaHoverLogo from '@/assets/images/kroma-logo-white-hover.png';
import { ReactComponent as ArrowUp } from '@/assets/icons/arrow-up.svg';
import { mobileSize } from '@/styles/theme';

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <CopyrightLabel>© LIGHTSCALE Inc. 2022. All Rights Reserved.</CopyrightLabel>
        <KromaLink href="https://kroma.network" target="_blank" rel="noopener noreferrer nofollow">
          <img src={KromaLogo} alt="kroma-logo" className="logo" />
          <img src={KromaHoverLogo} alt="kroma-logo-hover" className="logo_hover" />
          <ArrowUp />
        </KromaLink>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  padding: 25px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media all and (max-width: ${mobileSize}) {
    padding: 32px 30px;
  }
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: ${mobileSize}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 34px;
  }
`;

const CopyrightLabel = styled.p`
  color: ${({ theme }) => theme.colors.primary[50]};
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
`;

const KromaLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.003em;

  img {
    width: 86px;
    height: 20px;

    &.logo {
      display: block;
    }

    &.logo_hover {
      display: none;
    }
  }

  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    img {
      &.logo {
        display: none;
      }

      &.logo_hover {
        display: block;
      }
    }
    svg path {
      fill: ${({ theme }) => theme.colors.primary[50]};
    }
  }
`;
