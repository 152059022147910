const colors = {
  blue: '#0066ff',
  black: '#222222',
  black10: '#000000',
  grey10: '#cccccc',
  grey20: '#909090',
  purple10: '#f5f3ff',
  purple20: '#dcd7fd',
  purple30: '#3b2b92',
  orange: '#f0b90b',
  red: '#ff0000',
  white: '#fff',

  primary: {
    50: '#D2E4FF',
    100: '#B6D3FF',
    200: '#89B8FF',
    300: '#66A3FF',
    400: '#297FFF',
    500: '#0066FF',
    600: '#004FC5',
  },
  gray: {
    50: '#F0F0F2',
    100: '#E1E2E5',
    200: '#CFD1D6',
    300: '#AEB2BC',
    400: '#878D9A',
    500: '#6A7181',
    600: '#5D6575',
    700: '#4E5565',
    800: '#3E4352',
    900: '#2B2D38',
    950: '#151619',
  },
  foil: `linear-gradient(257.95deg, rgba(0, 255, 224, 0.15) 4.89%, rgba(0, 255, 224, 0) 33.78%), radial-gradient(4.93% 100% at 67.5% 0%, rgba(202, 202, 202, 0.15) 0%, rgba(202, 202, 202, 0.15) 100%), radial-gradient(36.94% 144.69% at 46.09% 0%, rgba(253, 132, 255, 0.15) 0%, rgba(253, 132, 255, 0) 100%), linear-gradient(112.48deg, rgba(0, 163, 255, 0.15) 14.59%, rgba(0, 163, 255, 0) 55.06%), #FFFFFF`,
  gradient: {
    blue500: `linear-gradient(180deg, rgba(0, 102, 255, 0.1) 0%, rgba(0, 102, 255, 0.9) 100%)`,
    blue600: `linear-gradient(90deg, #004FC5 0%, #0066FF 16.67%, #297FFF 33.33%, #66A3FF 50%, #89B8FF 67.71%, #B6D3FF 83.33%, #D2E4FF 100%)`,
    gray: `linear-gradient(90deg, #151619 4.79%, #2B2D38 13.84%, #3E4352 22.9%, #4E5565 31.96%, #5D6575 41.02%, #6A7181 50.31%, #878D9A 59.37%, #AEB2BC 68.91%, #CFD1D6 77.49%, #E1E2E5 86.78%, #F7F7F7 94.89%)`,
  },
};

const mobileSize = '1024px';
const maxWidth = '1740px';

// common property
const common = {
  flexCenter: `
    display: flex;
    justify-contents: center;
    align-items: center;
  `,
  flexCenterColumn: `
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
  `,
  width: `
    width: 100%;
    max-width: ${maxWidth};
  `,
};

const theme = {
  colors,
  common,
};

export { theme, mobileSize, maxWidth };
export type ITheme = typeof theme;
