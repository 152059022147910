import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from '@/constants/lang/en.json';
import ko from '@/constants/lang/ko.json';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      ko: ko,
      en: en,
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
