import styled from 'styled-components';
import { motion } from 'framer-motion';
import { routeVariants } from '@/constants/animations';
import { mobileSize } from '@/styles/theme';

const MissionPage = () => {
  return (
    <MissionPageWrapper initial="top" animate="enter" variants={routeVariants}>
      <MissionLabel>Mission</MissionLabel>
      <MissionTitle>
        To create a future where blockchain technology serves as essential infrastructure for borderless and limitless
        digital world with global connectivity.
      </MissionTitle>
      <MissioSubtitle>{`To accelerate the adoption of blockchain technology in everyday life\nwe aim to solve the blockchain trilemma while enhancing usability, throughput, latency, and reducing fees.`}</MissioSubtitle>
      <MobileMissioSubtitle>
        To accelerate the adoption of blockchain technology in everyday life, we aim to solve the blockchain trilemma
        while enhancing usability, throughput, latency, and reducing fees.
      </MobileMissioSubtitle>
    </MissionPageWrapper>
  );
};

export default MissionPage;

const MissionPageWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const MissionLabel = styled.p`
  color: ${({ theme }) => theme.colors.primary[500]};
  font-family: 'ChakraPetch';
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 16px;

  @media all and (max-width: ${mobileSize}) {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 12px;
    font-weight: 600;
  }
`;

const MissionTitle = styled.h1`
  color: ${({ theme }) => theme.colors.gray[950]};
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: -0.003em;
  margin-bottom: 24px;

  @media all and (max-width: ${mobileSize}) {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 32px;
  }
`;

const MissioSubtitle = styled.h1`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.003em;
  display: block;
  white-space: pre-wrap;

  @media all and (max-width: ${mobileSize}) {
    display: none;
  }
`;
const MobileMissioSubtitle = styled.h1`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-weight: 500;
  letter-spacing: -0.003em
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 32px;
  display: none;
  @media all and (max-width: ${mobileSize}) {
    display: block;
  }
`;
