import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { mobileSize } from '@/styles/theme';
import useMediaQuery from '@/hooks/useMediaQuery';

const MouseMoveSection = () => {
  const isMobileSize = useMediaQuery(`(max-width: ${mobileSize})`);
  const mouseCenterPosition = useMemo(() => (isMobileSize ? 150 : 375), [isMobileSize]);

  const [mousePosition, setMousePosition] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  useEffect(() => {
    const updateMousePosition = (e: MouseEvent) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return (
    <MouseMovePointer
      variants={{ default: { x: mousePosition.x - mouseCenterPosition, y: mousePosition.y - mouseCenterPosition } }}
      animate="default"
      transition={{
        x: {
          duration: 0.3,
          ease: 'linear',
          repeat: 0,
          type: 'spring',
          stiffness: 30,
        },
        y: {
          duration: 0.3,
          ease: 'linear',
          repeat: 0,
          type: 'spring',
          stiffness: 30,
        },
        default: {
          duration: 5,
          repeat: Infinity,
        },
      }}
    />
  );
};

export default MouseMoveSection;

const MouseMovePointer = styled(motion.div)`
  position: fixed;
  width: 750px;
  height: 750px;
  border-radius: 50%;
  filter: blur(180px);
  background: radial-gradient(circle at 50% 50%, rgba(0, 102, 255, 0.2) 0%, rgba(0, 102, 255, 0.1) 100%);
  z-index: -1;

  @media all and (max-width: ${mobileSize}) {
    filter: blur(100px);
    width: 300px;
    height: 300px;
  }
`;
